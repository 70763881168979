import React, { useEffect } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { Divider } from "semantic-ui-react";
import { useMyGlobal } from "../../component/GlobalData";

export const Termofuse = () => {
    const { windowWidth, isPortrait } = useMyGlobal()

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    return (
        <Container style={windowWidth > 1000 && !isPortrait ? { marginTop: '8rem' } : { marginTop: '6rem'}}>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} sl={12}>
                    <h2 className="text-uppercase">Term of Use</h2>
                    <Divider horizontal style={windowWidth < 1000 && !isPortrait ? { fontSize: '0.6em' } : {}}>These are the terms of use of the Rudrunner online store</Divider>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} sl={12} className="d-flex justify-items-start mt-3">
                    <div className="text-start" style={windowWidth < 1000 && !isPortrait ? { fontSize: '0.9em' } : {}}>
                        <p>Date of Revision : July 2024</p>
                        <p>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING ANY RUDRUNNER.CO.ID PLATFORM</p>
                        <p>Welcome to RUDRUNNER.CO.ID Indonesia community! You are reading these Terms of Use because you are using RUDRUNNER.CO.ID website, digital experience, social media platform, mobile app, wearable technology, or one of our other products or services, all of which are part of RUDRUNNER.CO.ID's Platform. You are reading these “Platform”.  You may access the Platform through a computer, mobile phone, tablet, console, or other technology, which we refer to here as a “Device”.  Your carrier's normal rates and fees apply to your Device.</p>
                        <p>These Terms create a legally binding agreement between you and RUDRUNNER.CO.ID and its affiliates (which we may refer to as “RUDRUNNER.CO.ID,” “we,” “us,” or “our”) regarding your use of the Platform. Please review our List of Local Entities for the name of the RUDRUNNER.CO.ID entity responsible for providing the Platform to you and the appropriate contact information. A few important points:</p>
                        <ul>
                            <li>Our Terms May Change.  Some jurisdictions do not permit unilateral updates or changes to consumer terms, so this paragraph may not apply to you.  We may update these Terms from time to time.  If a material change is made, we will post a notice on the Platform or send you a notification.  Read through any changes, and if you don’t agree to them, please stop using the Platform.  If you continue to use our Platform after we notify you of changes, you will be deemed to have accepted the updated Terms, except to the extent prohibited by applicable law.  </li>
                            <li>Terms of Sale.  By making any purchase with us, you also agree to the Terms of Sales that apply in your country or region. </li>
                            <li>Privacy Policy.  Our Privacy Policy describes the collection and use of personal information on the Platform and applies to your use of the Platform.</li>
                            <li>Important Notice for Amateur Athletes.  You are responsible for ensuring that your participation on the Platform does not affect your eligibility as an amateur athlete.  Please check with your amateur athletic association for the rules that apply to you.  RUDRUNNER.CO.ID is not responsible or liable for your use of the Platform resulting in your ineligibility as an amateur athlete. </li>
                        </ul>
                        <p>1. GROUND RULES</p>
                        <p>You are only eligible to use the Platform if you are of legal age in your country or if you have consent from your parent or guardian.  There may be certain age restrictions for specific Platform services in various countries.</p>
                        <p>Rules for Registration.  When you register for an account with us, the following rules apply:</p>
                        <ul>
                            <li>Be True:  Provide accurate and current registration information.</li>
                            <li>Be You:  Keep your registration personal.  Do not register for more than one RUDRUNNER.CO.ID account, register a RUDRUNNER.CO.ID account on behalf of someone else, or transfer your account to someone else.</li>
                            <li>Be Secure:  Keep your username, password and other login credentials secure and do not allow anyone else to use your account.</li>
                            <li>Be Responsible:  Inform RUDRUNNER.CO.ID immediately of any unauthorized use of your RUDRUNNER.CO.ID account.  You are responsible for anything that happens through your RUDRUNNER.CO.ID account – with or without your permission. TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, RUDRUNNER.CO.ID IS NOT RESPONSIBLE FOR ANY LOSS OR ACTIVITY THAT RESULTS FROM THE UNAUTHORIZED USE OF YOUR ACCOUNT.</li>
                        </ul>
                        <p>2.  OWNERSHIP OF CONTENT</p>
                        <p>Except for User Content (defined below), all of the content on our Platform - including text, software, scripts, code, designs, graphics, photos, sounds, music, videos, applications, interactive features, articles, news stories, sketches, animations, stickers, general artwork and other content ("Content") - is owned by RUDRUNNER.CO.ID or others we license Content from, and is protected by copyright, trademark, patent and other laws. RUDRUNNER.CO.ID reserves all rights not expressly described in these Terms.</p>
                        <ul>
                            <li>All trademarks, service marks and trade names (e.g., the RUDRUNNER.CO.ID name and the Basketball Logo) are owned, registered and/or licensed by RUDRUNNER.CO.ID.  You do not acquire a license or any ownership rights to any trademarks, service marks, or trade names through your access or use of the Platform or Content.</li>
                            <li>You agree not to change or delete any ownership notices from materials downloaded or printed from the Platform.</li>
                            <li>To the extent RUDRUNNER.CO.ID approves the download or use of Content comprised of copyrights or copyrightable works, RUDRUNNER.CO.ID grants you a limited, personal, non-transferable, non-sublicensable, and revocable license to access and use such copyrights or copyrightable works solely for their intended purpose and solely for as long as RUDRUNNER.CO.ID makes such Content generally available to the public. You do not acquire any ownership rights in the Content (including any trademarks or other intellectual property included in the Content), and all such Content is intended for personal, non-commercial use.  RUDRUNNER.CO.ID reserves the right to monitor your use and to alter or revoke this license or your access to the Content at any time and for any reason.  RUDRUNNER.CO.ID reserves the right to take down any Content in violation of these terms or RUDRUNNER.CO.ID intellectual property rights.  RUDRUNNER.CO.ID allowing you this limited use does not constitute a waiver of any of RUDRUNNER.CO.ID rights to the Content. </li>
                            <li>Outside of the specific usage rights granted to you by RUDRUNNER.CO.ID in connection with the Platform, you agree not to use, copy, edit, translate, display, distribute, download, transmit, sell, create derivative works of, or in any way exploit any Content, including User Content (unless it is your own User Content that you legally post on the Platform), without RUDRUNNER.CO.ID prior written consent. Unauthorized use of the Content may constitute a breach of copyright, trademark or other intellectual property laws and may subject you to criminal or civil charges and penalties.</li>
                        </ul>
                        <p>3. POSTING CONTENT ON THE PLATFORM</p>
                        <p>User Content License. Some parts of the Platform allow you to post photos, videos, comments, and other content, which we refer to as “User Content.”  RUDRUNNER.CO.ID is not responsible for User Content others post to the Platform. User Content is owned by you or whoever created it, but when you post User Content you license it to RUDRUNNER.CO.ID as described below:</p>
                        <ul>
                            <li>You represent that you have the right to post your User Content, and you grant RUDRUNNER.CO.ID a non-exclusive, perpetual, transferable, sub-licensable, royalty-free, worldwide license to use any of the User Content that you post on or in connection with the Platform, including the likeness of any person that appears in the User Content, or any of the concepts or ideas contained in the User Content, for any purpose, including commercial use, which includes the right to translate, display, reproduce, modify, create derivative works, sublicense, distribute and assign these rights. RUDRUNNER.CO.ID may, in its sole discretion, remove any User Content at any time.</li>
                            <li>You understand that deleted User Content may persist in RUDRUNNER.CO.ID systems and on the Platform to the extent your User Content has been publicly posted or shared with others who have not deleted it unless you or the relevant individual request deletion or blocking of personal data in accordance with applicable law. </li>
                        </ul>
                        <p>LICENSE TO USE COMMENTS, FEEDBACK, AND IDEAS.  You understand that any comments, feedback, or ideas you send us are provided on a non-confidential basis and you grant to RUDRUNNER.CO.ID a perpetual, worldwide license to use all comments, feedback, and ideas you may share with us, without notice, compensation or acknowledgement to you, for any purposes whatsoever, including, but not limited to, developing, manufacturing and marketing products and services and creating, modifying or improving products and services.</p>
                        <p>4. USER CODE OF CONDUCT</p>
                        <p>We are excited to have you contribute to the RUDRUNNER.CO.ID community.  Here are a few basic rules:</p>
                        <ul>
                            <li>Be Original.  Only post User Content to the Platform if you have all permissions and rights needed to make that User Content available, including from any individuals who appear or are mentioned in your User Content. </li>
                            <li>
                                <li>Be Safe.</li> 
                                <ul>
                                    <li>Do not do anything that may expose RUDRUNNER.CO.ID or its users to any type of harm, including anything that may disrupt, damage, disable, tamper with, overburden or limit the functionality of the Platform.</li>
                                    <li>Do not post User Content that contains software viruses, programs or other computer code, and do not circumvent or modify any Platform software or security technology.</li>
                                    <li>Do not use any data mining, robots, scraping or similar data gathering methods.</li>
                                    <li>Unless we indicate otherwise, our Platform is a public place.  Do not post personal information to the Platform - yours or anybody else’s.</li>
                                </ul>
                                <li>Be Personal. </li>
                                <ul>
                                    <li>Do not post any advertising, solicitation or commercial content on the Platform or accept payment from a third party in exchange for performing commercial activity on the Platform.</li>
                                    <li>Do not collect or solicit personal information from other Platform users or send unsolicited messages.</li>
                                    <li>Do not use automated technology to interact with the Platform.</li>
                                    <li>Be Appropriate. Respect the community and do not post User Content, link to a website, or do anything that is illegal, misleading, malicious, harassing, inaccurate, discriminatory, or otherwise objectionable or inappropriate or which violates any applicable laws. RUDRUNNER.CO.ID has the right to pre-screen, monitor, or remove User Content – but we have no obligation to do so.</li>
                                    <li>Be Yourself.  Do not impersonate any person or organization, including athletes or RUDRUNNER.CO.ID employees.</li>
                                    <li>HAVE FUN!</li>
                                </ul>
                            </li>
                        </ul>
                        <p>5. COPYRIGHT INFRINGEMENT</p>
                        <p>Please consult your legal advisor before filing a notice with us because there may be penalties for false claims.  RUDRUNNER.CO.ID may terminate the accounts of Platform users found to infringe third party copyrights.</p>
                        <p>If you believe that your work has been improperly copied to the Platform, such that it constitutes infringement, please provide us with the following information. </p>
                        <p>(1) name, address, telephone number, email address and an electronic or physical signature of the copyright owner or of the person authorized to act on his/her behalf.</p>
                        <p>(2) a description of the copyrighted work that you claim has been infringed.</p>
                        <p>(3) a description of where on the Platform the content that you claim is infringing is located.</p>
                        <p>(4) a written statement that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and</p>
                        <p>(5) a statement by you, made under penalty of perjury (depending on applicable law), that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</p>
                        <p>6. PARTNERS ON THE PLATFORM </p>
                        <p>From time to time, RUDRUNNER.CO.ID may link to or partner with third-party websites, social media platforms, mobile apps, and other products and services (“Third Parties”).  You may be able to connect with these Third Parties through the Platform, but this does not mean RUDRUNNER.CO.ID endorses, monitors, or has any control over these Third Parties or their activities, which are subject to separate terms of use and privacy policies. You should carefully review any Third Party’s sites and terms of use and privacy policy. RUDRUNNER.CO.ID is not responsible for the content, policies, or activities of Third Parties and you interact with Third Parties at your own risk.</p>
                        <p>7. IMPORTANT DISCLAIMERS</p>
                        <p>PHYSICAL ACTIVITY.  The Platform may include features that promote physical activity, nutrition, or general wellness.  They are for your informational purposes only and are not intended as medical advice or services, or for diagnostic or treatment purposes.  </p>
                        <ul>
                            <li>Consider the risks involved and consult with your medical professional before engaging in any physical activity. </li>
                            <li>
                                <p>Never disregard professional medical advice or delay in seeking it because of something you have viewed on the Platform.  </p>
                                <ul>
                                    <li>TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, RUDRUNNER.CO.ID IS NOT RESPONSIBLE OR LIABLE FOR ANY INJURIES OR DAMAGES YOU MAY SUSTAIN THAT RESULT FROM YOUR USE OF, OR INABILITY TO USE, THE FEATURES ON THE PLATFORM.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>USER INTERACTIONS.  To the extent allowed by applicable law, we are not responsible for your interactions with other users of the Platform or any damage or harm you may experience because of these interactions. </p>
                        <ul>
                            <li>Be responsible and take precautions when interacting with other users (including users you do not know) on the Platform. Before you meet another person face-to-face, consider investigating, bringing a friend, choosing public locations, and letting someone know where you will be.  RUDRUNNER.CO.ID is under no obligation to become involved with any user dispute but may do so at its own discretion.</li>
                        </ul>
                        <p>Be responsible and take precautions when interacting with other users (including users you do not know) on the Platform. Before you meet another person face-to-face, consider investigating, bringing a friend, choosing public locations, and letting someone know where you will be.  RUDRUNNER.CO.ID is under no obligation to become involved with any user dispute but may do so at its own discretion.</p>
                        <ul>
                            <li>The Platform, Content, and the materials and products on this Platform are provided "AS IS.” We are not making any promises of any kind, including about the Platform’s accuracy, adequacy, usefulness, reliability or otherwise. TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, RUDRUNNER.CO.ID IS NOT RESPONSIBLE OR LIABLE FOR ANY USER CONTENT POSTED ON THE PLATFORM. </li>
                            <li>RUDRUNNER.CO.ID do not guarantee that the Platform will be uninterrupted or error-free, that any defects will be corrected, or that the Platform is free of viruses or anything else harmful.  </li>
                            <li>To the fullest extent permitted by law, RUDRUNNER.CO.ID disclaims all warranties, express or implied, regarding the Platform, Content, User Content and any products or services you may obtain or access through the Platform, including, but not limited to, implied warranties of title, merchantability, fitness for a particular purpose and non-infringement.</li>
                            <li>You are solely responsible for any damage to your Device resulting from accessing the Platform, to the extent applicable law does not provide otherwise.</li>
                            <li>We hope you enjoy and get the full benefit of the Platform; however, we do not guarantee any results.</li>
                        </ul>
                        <p>8. TERMINATION</p>
                        <p>RUDRUNNER.CO.ID may terminate or modify any RUDRUNNER.CO.ID Platform, member program, product or service at any time without notice.</p>
                        <p>RUDRUNNER.CO.ID may terminate or suspend your account, delete your profile or any of your User Content, and restrict your use of all or any part of the Platform at any time and for any reason, without any liability to RUDRUNNER.CO.ID, subject to applicable law.</p>
                        <ul>
                            <li>You understand and agree that some of your User Content, particularly that which is displayed in an activity feed or in other public places on the Platform, may continue to appear publicly even after your account is terminated, subject to your right to have your User Content removed upon request in accordance with applicable law.</li>
                            <li>These Terms remain in effect even after your account is terminated or you have stopped using the Platform.</li>
                        </ul>
                        <p>INDEMNIFICATION / LIMITATION OF LIABILITY</p>
                        <p>We want you to enjoy our Platform, but RUDRUNNER.CO.ID must also protect itself from any damages you may cause.</p>
                        <p>Indemnification and RELEASE.  Some jurisdictions do not permit certain limitations or exclusions on liabilities, legal warranties and remedies, so these exclusions limitations may not apply to you. You agree to indemnify, defend, and hold harmless RUDRUNNER.CO.ID Inc., its affiliates, officers, directors, employees, agents, licensors and suppliers (the “RUDRUNNER.CO.ID Parties”) from and against all claims, losses, liabilities, expenses, damages and costs, including, without limitation, attorneys' fees, arising from or relating in any way to your User Content, your use of Content, your use of the Platform, your conduct in connection with the Platform or with other Platform users, or any violation of these Terms of Use, any law or the rights of any third party. You, for yourself and on behalf of your heirs, estate, insurers, successors, and assigns, hereby fully and forever release and discharge the RUDRUNNER.CO.ID Parties from any and all claims or causes of action you may have for damages relating in any way to your use of the Platform.</p>
                        <p>LIMITATION OF LIABILITY. Some jurisdictions do not permit certain limitations or exclusions on liabilities, legal warranties, and remedies, so these exclusions/limitations may not apply to you. NONE OF THE RUDRUNNER.CO.ID PARTIES WILL BE LIABLE FOR ANY DIRECT, SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES, INCLUDING WITHOUT LIMITATION FOR ANY LOST PROFITS OR LOST DATA, THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE PLATFORM OR THE PERFORMANCE OF THE PRODUCTS PURCHASED THROUGH THE PLATFORM OR THE CONDUCT OF OTHER PLATFORM USERS (WHETHER ONLINE OR OFFLINE), OR ATTENDANCE AT A RUDRUNNER.CO.ID EVENT OR RUDRUNNER.CO.ID PARTNER EVENTS, OR ANY USER CONTENT OR ANY OTHER ACTIVITY IN CONNECTION WITH THE USE OF THE PLATFORM, EVEN IF RUDRUNNER.CO.ID HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE PLATFORM. YOUR ONLY REMEDY AGAINST RUDRUNNER.CO.ID IN CONNECTION WITH ANY DAMAGES ARISING FROM YOUR USE OF THE PLATFORM OR ANY CONTENT IS TO STOP USING THE PLATFORM. IF RUDRUNNER.CO.ID IS FOUND TO BE LIABLE TO YOU FOR ANY DAMAGE OR LOSS WHICH IS IN ANY WAY CONNECTED WITH YOUR USE OF THE PLATFORM OR ANY CONTENT, RUDRUNNER.CO.ID' LIABILITY SHALL NOT EXCEED US$100.00 OR EURO 100.00 IF YOU LIVE IN EUROPE.  </p>
                        <p>10. APP MARKETPLACES: You acknowledge that this agreement is between you and RUDRUNNER.CO.ID only, and not with the app marketplace where you downloaded a RUDRUNNER.CO.ID app (e.g. for iOS users, the App Store operated by Apple Inc. or for Android users, the Google Play store operated by Google Inc.).   </p>
                        <p>11. DISPUTES / ADDITIONAL TERMS</p>
                        <p>Choice of Law/Jurisdiction</p>
                        <p>If you live in any of the following countries, different “Choice of Law/Jurisdiction”, Terms may differ. You agree that this Platform is a passive platform solely based in Bandung, West Java, Indonesia, which does not give rise to personal jurisdiction over RUDRUNNER.CO.ID in jurisdictions other than Indonesia.</p>
                        <ul>
                            <li>You agree that the Platform, Terms, Privacy Policy and any dispute between you and RUDRUNNER.CO.ID shall be governed in all respects by Indonesia law, without regard to choice of law provisions, Convention on Contracts for the International Sale of Goods.</li>
                            <li>Except where prohibited by applicable law, and without limitation to any statutory rights for consumers, you agree that all disputes, claims and legal proceedings directly or indirectly arising out of or relating to the Platform (including but not limited to the purchase of RUDRUNNER.CO.ID products) shall be resolved individually, without resort to any form of class action, and exclusively in the state or federal courts located in Jakarta, Indonesia.</li>
                            <li>You consent to waive all defenses of “lack of personal jurisdiction” and “inconvenient forum” with respect to venue and jurisdiction in the state and federal courts of Indonesia.</li>
                            <li>All claims shall be brought within one (1) year after the claim arises, except to the extent a longer period is required by applicable law.</li>
                        </ul>
                        <p>Electronic Communications</p>
                        <ul>
                            <li>By using the Platform, you agree to receive certain electronic communications from RUDRUNNER.CO.ID, subject to applicable law.</li>
                            <li>You agree that any notice, agreement, disclosure or other communication that RUDRUNNER.CO.ID sends you electronically will satisfy any legal communication requirements, including that such communications be in writing.</li>
                        </ul>
                        <p>Right to Assign, No Waivers, Severability</p>
                        <ul>
                            <li>RUDRUNNER.CO.ID may assign its rights and duties under these Terms to any party at any time without notice to you, unless notice to you is required by applicable law, but this will not affect your rights or our obligations under these Terms. </li>
                            <li>RUDRUNNER.CO.ID’ failure to insist upon or enforce strict performance of these Terms is not a waiver of any of these Terms or RUDRUNNER.CO.ID’ rights. Users should always assume these Terms apply.</li>
                            <li>If any provision in these Terms is held invalid or unenforceable, the remainder of these Terms shall continue to be enforceable.</li>
                        </ul>
                        <p>Thanks for reading.  Please enjoy our community!</p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}