import React from "react";
import { useEffect, useState } from "react";
import { Card, Form, ListGroup } from 'react-bootstrap'
import { Button, Icon, Label} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Toast } from "./Toast";
import { accentColor } from "./utility";


const SummaryCart = ({ cart, totalItem, totalPrice, voucher, url, label, customerAddress, setModalPayment, page, courier, showModalmodalVoucher, setCart }) => {
    const navigate = useNavigate(); 
    const idCustomer = (localStorage.getItem('loginDataRr')) ? JSON.parse(localStorage.getItem('loginDataRr')).id : null
    const [shippingCost, setShippingCost] = useState(0)
    const token = (localStorage.getItem('loginDataRr')) ? JSON.parse(localStorage.getItem('loginDataRr')).token : null
    const [load, setLoad] = useState(false)

    // let shippingCost = (cart.summary.voucher_ongkir && cart.summary.voucher_ongkir > 0 && parseFloat(courier.service.price) > parseFloat(cart.summary.voucher_ongkir)) ? 
    // (courier) ? (courier.service) ? parseFloat(courier.service.price) - parseFloat(cart.summary.voucher_ongkir) : (cart.summary.service_cost > 0) ? parseFloat(cart.summary.service_cost) - parseFloat(cart.summary.voucher_ongkir): 0 : 0 :
    // (courier) ? (courier.service) ? parseFloat(courier.service.price) : parseFloat(cart.summary.service_cost) : 0 

    const calcShippingCost = () => {
        if (courier !== null && typeof courier.service !== 'undefined'){
            let shipCost = parseFloat(courier.service.price)
            let dscOngkir = cart.summary.voucher_ongkir 

            if (shipCost > dscOngkir){
                setShippingCost(shipCost - dscOngkir)
            } else {
                setShippingCost(shipCost - shipCost)
            }
        } 
    }

    const updateQty = () => {
        let param = cart.products
        
        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner/controler/order.php/?index=5`,
            headers: {
                'Content-Type': 'application/json',
                'authorization':token
            },
            data : param
        })
        .then(res => { 
            
        })
    }


    const createOrder = (data) => { 
        setLoad(true)
        data.summary.date = moment().format('YYYY-MM-DD H:m:s')
        const param = new URLSearchParams()
        //HEADER PARAM
        param.append('id_customer' , idCustomer)
        param.append('selected_voucher', JSON.stringify(data.selected_voucher))
        axios ({
            method: "POST",
            url: `${global.SRV}api_rudrunner/controler/order.php/?index=1`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization' : token
            },
            data : param
        })
        .then(response => { 
            if (response.data.state === 200){ 
                setLoad(false)
                cart.summary.expiry_time =response.data.expiry_time
                cart.summary.order_id = response.data.order_id
                cart.is_ordered = 1 //UBAH STATE ORDER JADI 1
                updateQty()
                setCart(cart)
                navigate(url)
            }else if (response.data.state === 500){
                alert(response.data.message)
            }else{
                alert('Create order failed')
            }
        })
    }

    // useEffect(() => {
    //     createOrder(cart)
    // },[cart])

    useEffect(() => {
        calcShippingCost()
    }, [courier])

    
    return (
        <section>
            <Card className='text-start'>
                <ListGroup variant="flush" className="">
                    {
                        (label !== 'Select Payment') ? ( (cart.is_ordered == 0 || !cart.hasOwnProperty('is_ordered')) &&
                            <ListGroup.Item className="p-3">
                                <Button color="teal" content="Shopping with voucher" labelPosition='right' icon="right arrow" fluid size="large" basic onClick={() => showModalmodalVoucher()}/>
                            </ListGroup.Item>
                        ) : (<></>)
                    }

                    <ListGroup.Item className="p-3">
                        <h4>Summary</h4>
                        <div className='d-flex justify-content-between'>
                            <div>Total ({totalItem} item's) : </div>
                            <div>{global.IDR.format(totalPrice)}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Voucher : </div>
                            <div style={{color:accentColor}}>-{global.IDR.format(cart.summary.voucher)}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Shipping : </div>
                            <div>{global.IDR.format(shippingCost)} {cart.summary.voucher_ongkir && cart.summary.voucher_ongkir > 0  ? <span style={{color:'#FF5733'}}>(-{global.IDR.format(cart.summary.voucher_ongkir)})</span> : <></>}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Shipping Insurance : </div>
                            <div>{global.IDR.format(cart.summary.asuransi)}</div>
                        </div>
                        <hr />
                        <div className='d-flex justify-content-between'>
                            <div className="fw-bold">Grand Total :</div>
                            <div style={{color:accentColor}} className="fw-bold">{global.IDR.format((totalPrice + shippingCost) + cart.summary.asuransi - cart.summary.voucher)}</div>
                        </div>
                        <div className="mt-4">
                            <Button
                                loading={load}
                                labelPosition="right"
                                icon="arrow alternate circle right outline"
                                content={label}
                                color="black"
                                fluid
                                size="large"
                                disabled={(cart.is_ordered == 0) ?  false : (page === 'cart' && cart.hasOwnProperty('is_ordered')) ? true : false}
                                onClick={() => {
                                    if (parseInt(totalPrice) > 20000 && totalPrice != null && typeof totalPrice != 'undefined'){
                                        if (url !== null) { // CART
                                            createOrder(cart)
                                        } else if (setModalPayment !== null && page === 'checkout') { // CHECKOUT
                                            if (customerAddress !== null) { 
                                                if (courier && courier.courier && courier.service) { 
                                                    setModalPayment(true)
                                                } else {
                                                    alert('Please select one courier')
                                                }
                                            } else if (customerAddress == null) {
                                                alert('Address cannot be empty')
                                            } else if (courier == null) {
                                                alert('Courier cannot be empty')
                                            }
                                        }

                                        if (url == null && setModalPayment == null && page === 'cart') {
                                            alert("If you haven't added any products to your shopping cart yet, please select a product first")
                                        }
                                    } else {
                                        Toast.fire({
                                            icon: "error",
                                            text : "there`s error : Item price too low, you can cancel this order, and try to place order again",
                                            position:'center'
                                        })
                
                                    }
                                }}
                            />
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Card>
        </section>
    )
}

export default SummaryCart;