import React from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import { Tab, TabPane } from "semantic-ui-react";
import { SiNike, SiJordan, SiAdidas, SiPuma, SiUnderarmour } from "react-icons/si";
import { RiStarSFill } from "react-icons/ri";
import nikeshoes from "../../assets/nike/SHOES/NIKE SHOES-100.jpg"
import niketop from "../../assets/nike/TOPS/NIKE TOPS-100.jpg"
import nikebottom from "../../assets/nike/BOTTOMS/NIKE BOTTOMS-100.jpg"
// import jordanshoes from "../../assets/jordan/SHOES/AIR JORDAN SHOES-100.jpg"
// import jordantop from "../../assets/jordan/TOPS/AIR JORDAN TOPS-100.jpg"
// import jordanbottom from "../../assets/jordan/BOTTOMS/AIR JORDAN BOTTOMS-100.jpg"
import adidasshoes from "../../assets/adidas/SHOES/ADIDAS SHOES-100.jpg"
import adidastop from "../../assets/adidas/TOPS/ADIDAS TOPS-100.jpg"
import adidasbottom from "../../assets/adidas/BOTTOMS/ADIDAS BOTTOMS-100.jpg"
import pumashoes from "../../assets/puma/SHOES/PUMA SHOES-100.jpg"
import pumatop from "../../assets/puma/TOPS/PUMA TOPS-100.jpg"
import pumabottom from "../../assets/puma/BOTTOMS/PUMA BOTTOMS-100.jpg"
// import convshoes from "../../assets/converse/SHOES/CONVERSE SHOES-100.jpg"
// import convtop from "../../assets/converse/TOPS/CONVERSE TOPS-100.jpg"
// import convbottom from "../../assets/converse/BOTTOMS/CONVERSE BOTTOMS-100.jpg"
// import uashoes from "../../assets/ua/SHOES/UA SHOES-100.jpg"
// import uatop from "../../assets/ua/TOPS/UNDER ARMOUR TOPS-100.jpg"
// import uabottom from "../../assets/ua/BOTTOMS/UNDER ARMOUR BOTTOMS-100.jpg"
import asics from "../../assets/asics/ASICS.jpg"
import nb from "../../assets/nb/NEW BALANCE.jpg"
import brooks from "../../assets/brooks/BROOKS.jpg"
import hoka from "../../assets/hoka/hoka.jpg"

import { useMyGlobal } from "../../component/GlobalData";

const SizeGuidePage = () => {
    const { token, windowWidth } = useMyGlobal()
    const panes = [
        {
            menuItem: { key: 'nike', icon: <SiNike className="me-2 fs-5"/>, content: windowWidth < 600 ? '' : 'Nike' },
            render: () => <TabPane>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={nikeshoes} fluid /></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={niketop} fluid /></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={nikebottom} fluid /></Col>
                </Row>
            </TabPane>
        },
        // {
        //     menuItem: { key: 'jordan', icon: <SiJordan className="me-2 fs-5" />, content: windowWidth < 600 ? '' : 'Jordan' },
        //     render: () => <TabPane>
        //         <Row>
        //             <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={jordanshoes} fluid /></Col>
        //             <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={jordantop} fluid /></Col>
        //             <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={jordanbottom} fluid /></Col>
        //         </Row>
        //     </TabPane>
        // },
        {
            menuItem: { key: 'adidas', icon: <SiAdidas className="me-2 fs-5" />, content: windowWidth < 600 ? '' : 'Adidas' },
            render: () => <TabPane>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={adidasshoes} fluid /></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={adidastop} fluid /></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={adidasbottom} fluid /></Col>
                </Row>
            </TabPane>
        },
        {
            menuItem: { key: 'puma', icon: <SiPuma className="me-2 fs-5" />, content: windowWidth < 600 ? '' : 'Puma' },
            render: () => <TabPane>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={pumashoes} fluid /></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={pumatop} fluid /></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={pumabottom} fluid /></Col>
                </Row>
            </TabPane>
        },
        {
            menuItem: { key: 'asics', content: 'Asics' },
            render: () => <TabPane>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={asics} fluid /></Col>
                </Row>
            </TabPane>
        },
        {
            menuItem: { key: 'nb', content: windowWidth < 600 ? '' : 'New Balance' },
            render: () => <TabPane>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={nb} fluid /></Col>
                </Row>
            </TabPane>
        },
        {
            menuItem: { key: 'brooks',  content: 'Brooks' },
            render: () => <TabPane>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={brooks} fluid /></Col>
                </Row>
            </TabPane>
        },
        {
            menuItem: { key: 'hoka',  content: 'Hoka' },
            render: () => <TabPane>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={hoka} fluid /></Col>
                </Row>
            </TabPane>
        },
        // {
        //     menuItem: { key: 'converse', icon: <RiStarSFill className="me-2 fs-5" />, content: windowWidth < 600 ? '' : 'Converse' },
        //     render: () => <TabPane>
        //         <Row>
        //             <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={convshoes} fluid /></Col>
        //             <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={convtop} fluid /></Col>
        //             <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={convbottom} fluid /></Col>
        //         </Row>
        //     </TabPane>
        // },
        // {
        //     menuItem: { key: 'ua', icon: <SiUnderarmour className="me-2 fs-5" />, content: windowWidth < 600 ? '' : 'Under Armour' },
        //     render: () => <TabPane>
        //         <Row>
        //             <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={uashoes} fluid /></Col>
        //             <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={uatop} fluid /></Col>
        //             <Col xs={12} sm={12} md={12} lg={12} xl={12}><Image src={uabottom} fluid /></Col>
        //         </Row>
        //     </TabPane>
        // },
    ]
    return (
        <Container style={{  marginTop: '6em' }}>
            <Row>
                <Col xs={12}>
                    <div className="display-6 mb-4">Size Guide</div>
                    <Tab panes={panes} menu={{ secondary: true }} />
                </Col>
            </Row>
        </Container>
    )
}

export default SizeGuidePage