import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row, Stack } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Input } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import axios from "axios";
import tridsLogo from "../../assets/3ds.png"
import { useMyGlobal } from "../../component/GlobalData";

const PaymentCard = ({cart, setCart}) => {
    const location = useLocation().state
    const navigate = useNavigate()
    const [card, setCard] = useState({})
    const [payStatus, setPayStatus] = useState('')
    const {token} = useMyGlobal()

    const addingPaymentCC = (data) => {
        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner/controler/payment.php/?index=8`,
            headers: {
                'Content-Type': 'text/plain',
                'authorization' : token
            },
            data: data
        })
        .then(res => {
            
        })
    }

    const updatePaymentCC = (data) => {
        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner/controler/payment.php/?index=9`,
            headers: {
                'Content-Type': 'text/plain',
                'authorization': token
            },
            data: data
        })
        .then(res => {
            let idCart = cart.summary.id_cart

            if (res.data.state === 200 || data.status_code === '200'){
                clearCart(idCart)
            }
        })
    }

    // CLEAR CART SETELAH PAYMENT SUKSES
    const clearCart = (idCart) => {
        const param = new URLSearchParams()
        param.append("id_cart", idCart)
        param.append("id_customer", cart.customer.id_customer)

        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner/controler/cart.php/?index=11`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        })
        .then(res => {
            if (res.data.state === 200){
                cart.products = []
            }
        })
    }

    const getAuth = (token_id) => {
        let itemDetails = cart.products.map(i => (
            {
                id:i.product_id,
                price:i.final_price,
                quantity:i.qty,
                name:i.product_name,
                size:i.selected_size,
                segment:i.segment_name,
                merchant_name:'Hoops Indonesia',
            }

        ))
        
        let itemShipping = {
            id:cart.summary.service,
            price:cart.summary.service_cost,
            quantity:1,
            name:'JNE' + cart.summary.service,
            discount : cart.summary.voucher_ongkir > 0 ? cart.summary.voucher_ongkir : 0,
        }

        let itemVoucher

        const getVoucherUse = (voucher) => {
            if (voucher.hasOwnProperty('discount_type')){
                if (voucher.discount_type === 'N') {
                    return voucher.discount_value
                }else if (voucher.discount_type === 'P'){
                    return (voucher.discount_percent * cart.summary.totalPrice) / 100
                }
            } else if (voucher.hasOwnProperty('type')){
                if (voucher.type === 'N'){
                    return voucher.nominal
                }else if (voucher.type === 'D'){
                    return (voucher.percent * cart.summary.totalPrice) / 100
                }
            }
        }

        if (cart.summary.voucher > 0){
            cart.selected_voucher.map(i => {
                itemDetails.push({
                    id : i.id_voucher,
                    price : -getVoucherUse(i),
                    quantity : 1,
                    name : i.voucher_name || i.name,
                    end_date : i.end_date,
                    voucher : i.hasOwnProperty('type') ? i.type : i.discount_type,
                })
            })
            
        }
        
        itemDetails.push(itemShipping)
        
        let req = {
            payment_type: "credit_card",
            transaction_details: {
                order_id: cart.summary.order_id,
                // order_id:moment().format('YMDhms'),
                gross_amount: cart.summary.total_order
            },
            credit_card: {
                token_id: token_id,
                authentication: true,
                callback_type: "js_event"
            },
            item_details: itemDetails,
            customer_details:{
                first_name:cart.customer.first_name,
                last_name:cart.customer.last_name,
                email: cart.customer.email,
                phone:cart.customer.address.celular_number,
                shipping_address:{
                    first_name: cart.customer.first_name,
                    last_name: cart.customer.last_name,
                    email:  cart.customer.email,
                    phone: cart.customer.address.celular_number,
                    address: cart.customer.address.address_detail,
                    city: cart.customer.address.city,
                    postal_code: cart.customer.address.zip_code,
                    country_code: "IDN"
                }

            }
        }
        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner/controler/payment.php/?index=7`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: req
        })
        .then(res => { 
            if (res.data.status_code === "201" || res.data.status_code === "200"){
                const redirect_url = res.data.redirect_url
                const resData = res.data

                var popupModal = (function(){
                    var modal = null;
                    return {
                        openPopup(url){
                        modal = window.picoModal({
                            content:'<iframe frameborder="0" style="height:90vh; width:100%;" src="'+url+'"></iframe>',
                            width: "85%",
                            closeButton: false,
                            overlayClose: false,
                            escCloses: false,
                        }).show();
                        },
                        closePopup(){
                        try{
                            modal.close();
                        } catch(e) {}
                        }
                    }
                }());
               
                let options = {
                    performAuthentication: function(redirect_url){
                        // Implement how you will open iframe to display 3ds authentication redirect_url to customer
                        popupModal.openPopup(redirect_url);
                        addingPaymentCC(resData)
                    },
                    onSuccess: function(response){
                        // 3ds authentication success, implement payment success scenario
                        popupModal.closePopup();
                        // // Simulate an HTTP redirect:
                        updatePaymentCC(response)
                        setPayStatus(response.status_message)
                        navigate(`/cart/checkout/payment_success?id_order=${resData.order_id}`, { state: { id_order: resData.order_id } });
                    },
                    onFailure: function(response){
                        // 3ds authentication failure, implement payment failure scenario
                        popupModal.closePopup();
                        updatePaymentCC(response)
                        setPayStatus(response.status_message)
                    },
                    onPending: function(response){
                        // transaction is pending, transaction result will be notified later via 
                        // HTTP POST notification, implement as you wish here
                        popupModal.closePopup();
                        setPayStatus(response.status_message)
                    }
                }
                  
                  // trigger `authenticate` function
                  window.MidtransNew3ds.authenticate(redirect_url, options);
            }
        })
    }
    
    let options = {
        onSuccess: function (response) {
            let options = {
                callbackUrl: "http://localhost:3000/checkout/paymentCard"
            }
            getAuth(response.token_id)
        },
        onFailure: function (response) {
            console.log(response)
        }
    }

    const handleSubmit = () => {
        window.MidtransNew3ds.getCardToken(card, options)
    }

    return (
        <>
            <Helmet>
                <script id="midtrans-script" src="https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js" data-environment="sandbox" data-client-key="SB-Mid-client-K2Lg7KkoZ9OgGj_m" type="text/javascript"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/picomodal/3.0.0/picoModal.js"></script>
            </Helmet>
            {location ?
                <section style={{ marginTop: "10rem", minHeight: "100vh", height: "100%", textAlign: 'left' }}>
                    <Container>
                        <Row className="d-flex justify-content-center">
                            <Col xl="6" lg={6} md={6} sm={8} className="mb-5 text-center">
                                <h2>Credit Card</h2>
                                <div>Please fill in the fields below correctly. You will be forwarded to the authentication process after you click the submit button, and you will receive a verification code.</div>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center"> 
                            <Col md={6} lg={4} sm={6} xs={12} className="mb-3">
                                <Form onSubmit={handleSubmit}>
                                    <Form.Input type="text" focus 
                                    placeholder="Ex : 1111222233334444"
                                    label="Card Number" 
                                    icon='credit card outline'
                                    value={card.card_number}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        if (e.target.value == '' || re.test(e.target.value)){
                                            if (e.target.value !== '' ) e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,16)
                                            setCard({ ...card, card_number: e.target.value })
                                        }else{
                                            e.target.value = null
                                        }
                                    }} 
                                    />
                                    <Form.Group className="mb-4">
                                        <Form.Input width="6" type="text" className="mb-3" 
                                        placeholder="123"
                                        label="CVV" 
                                        value={card.card_cvv} 
                                        onChange={(e) => {
                                            const re = /^[0-9\b]+$/;
                                            if (e.target.value == '' || re.test(e.target.value)){
                                                if (e.target.value !== '' ) e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,3)
                                                setCard({ ...card, card_cvv: e.target.value })
                                            }else{
                                                e.target.value = null
                                            }
                                        }} />
                                        <Form.Input width="6" type="text" className="mb-3" 
                                            placeholder="12"
                                            value={card.card_exp_month}
                                            label="Expire Month" 
                                            onChange={(e) => {
                                            const re = /^[0-9\b]+$/;
                                            if (e.target.value == '' || re.test(e.target.value)){
                                                if (e.target.value !== '' ) e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,2)
                                                setCard({ ...card, card_exp_month: e.target.value })
                                            }else{
                                                e.target.value = null
                                            }
                                        }} />
                                        <Form.Input width="6" type="text" 
                                        placeholder="2023"
                                        value={card.card_exp_year}
                                        label="Expire Year"  
                                        onChange={(e) => {
                                            const re = /^[0-9\b]+$/;
                                            if (e.target.value == '' || re.test(e.target.value)){
                                                if (e.target.value !== '' )  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                                                setCard({ ...card, card_exp_year: e.target.value })
                                            }else{
                                                e.target.value = null
                                            }
                                        }} />
                                    </Form.Group>
                                    <Button.Group fluid>
                                    <Button content="Submit" type="submit" size="large" style={{backgroundColor:'grey', color:'whitesmoke'}}/>
                                    <Button.Or/>
                                    <Button content="Cancel" type="reset" size="large" onClick={() => navigate('/cart/checkout')} />
                                    </Button.Group>
                                </Form>
                                <div className="mt-5 text-center" style={{color:'coral'}}>{payStatus}</div>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center mt-4">
                            <Col xs={5} sm={5} md={2} lg={2} xl={2} className="text-center">
                                <div className="border p-2">
                                    <span className="me-2"><Image src={tridsLogo} width={26} height={32}/></span>
                                    <span className="fs-6">3D Secure</span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                :
                <section style={{ marginTop: "10rem", minHeight: "100vh", height: "100%" }}>
                    <Container>
                        You're lost, let's go back
                    </Container>
                </section>
            }
        </>
    )
}

export default PaymentCard