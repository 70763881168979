import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Image, Modal, Row, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Dimmer, Divider, Icon, Loader } from "semantic-ui-react";
import Login from "../../component/Login";

export const RaffleConfirmPage = ({ cart, setCart }) => {
    const navigate = useNavigate()
    const { idRaffle, idRegistration } = useParams()
    const [raffleWinner, setRaffleWinner] = useState(null)
    const session = JSON.parse(window.localStorage.getItem('loginDataRr'))
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [login, setLogin] = useState(null)
    const [isLoged, setIsLoged] = useState(false)
    const [invalid, setInvalid] = useState(false)
    const [loaderPage, setLoaderPage] = useState(false)
    const [invalidUrl, seTinvalidUrl] = useState(false)
    const [expired, setExpired] = useState(false)

    const getWinnerData = async (idReg, idRaffle) => {
        setLoaderPage(true)
        await axios({
            method: "GET",
            url: `${global.SRV}/api_rudrunner/controler/event.php?index=10&id_reg=${idReg}&id_raffle=${idRaffle}`,
        })
            .then(res => {
                if (res.data.state === 200) {
                    const curTime = moment().utcOffset('+0700').format('YYYY-MM-DD HH:mm:ss')
                    setRaffleWinner(res.data.data)
                    setLoaderPage(false)
                  
                    setExpired((() => {
                        if (res.data.data.winner.expired_url <= curTime){
                            return true
                        } else { 
                            return false
                        }
                    })())
                } else if (res.data.state === 300) {
                    setLoaderPage(false)
                    seTinvalidUrl(true)
                }
            })
    }
    console.log(expired)
    useEffect(() => {
        getWinnerData(idRegistration, idRaffle)
    }, [])

    useEffect(() => {
        if (!session) {
            setShowLoginModal(true)
        } else if (cart.summary.id_cart) {
            const id_cart = cart.summary.id_cart
            if (raffleWinner) {
                if (raffleWinner.winner.id_cart === id_cart) {
                    setInvalid(false)
                } else if (raffleWinner.winner.id_cart !== id_cart) {
                    setInvalid(true)
                    setLoaderPage(false)
                }
            }
        }
    }, [cart.summary.id_cart, raffleWinner])

    useEffect(() => {
        if (isLoged) window.location.reload()
    }, [isLoged])
    console.log(raffleWinner)
    return (
        <Container style={{ marginTop: '15vh' }}>
            <Dimmer active={loaderPage} inverted>
                <Loader inverted content='Loading' />
            </Dimmer>
            <Row className="d-flex justify-content-center">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                    <h2 className="text-uppercase">Confirmation Page</h2>
                    <Divider horizontal>Raffle Winner confirmation page</Divider>
                </Col>
                {
                    (() => {
                        if (!invalid && cart.summary.id_cart !== null && !invalidUrl && !expired) {
                            return (
                            <Col xs={12} sm={12} md={10} lg={10} xl={6}>
                                <Icon name="check circle" color="orange" size="big" className="mb-2" />
                                <div className="mb-4 fw-bold">Congratulations you just won the Raffle for this product below</div>
                                <Image src={raffleWinner?.winner?.img} width={110} height={90} rounded className="mb-2 border" />
                                <div className="mb-3">{raffleWinner?.winner?.name} Size : {raffleWinner?.winner?.size}</div>
                                <Button icon="hand rock outline" content="Get this product" color="black" />
                            </Col>
                            )
                        } else if (invalid && !expired){
                            return (
                            <Col xs={12} sm={12} md={10} lg={10} xl={6} >
                                <Icon name="info circle" color="orange" size="big" className="mb-2" />
                                <div className="fw-bold mb-4">Invalid Login Data</div>
                                <div>There is a mismatch between the login data and the parameters in the url</div>
                                <div>You log in as <span className="fw-bold">{session.profile.email}</span> while the email is sent to <span className="fw-bold">{raffleWinner?.winner?.email}</span></div>
                            </Col>
                            )
                        } else if (invalidUrl && !expired){
                            return (
                            <Col xs={12} sm={12} md={10} lg={10} xl={6} >
                                <Icon name="info circle" color="orange" size="big" className="mb-2" />
                                <div className="fw-bold mb-4">The url you executed is invalid</div>
                            </Col>
                            )
                        } else if (expired){
                            return (
                            <Col xs={12} sm={12} md={10} lg={10} xl={6} >
                                <Icon name="info circle" color="orange" size="big" className="mb-2" />
                                <div className="fw-bold mb-2">Link is expired</div>
                                <div>The link you clicked is no longer valid</div>
                            </Col>
                            )
                        }
                    })()

                    // !invalid && cart.summary.id_cart !== null ?
                    // <Col xs={12} sm={12} md={10} lg={10} xl={6}>
                    //     <Icon name="check circle" color="orange" size="big" className="mb-2" />
                    //     <div className="mb-4 fw-bold">Congratulations you just won the Raffle for this product below</div>
                    //     <Image src={raffleWinner?.winner?.img} width={110} height={90} rounded className="mb-2 border" />
                    //     <div className="mb-3">{raffleWinner?.winner?.name} Size : {raffleWinner?.winner?.size}</div>
                    //     <Button icon="hand rock outline" content="Get this product" color="black" />
                    // </Col>
                    // :
                    // <Col xs={12} sm={12} md={10} lg={10} xl={6} >
                    //     <Icon name="info circle" color="orange" size="big" className="mb-2" />
                    //     <div className="fw-bold mb-4">Invalid Login Data</div>
                    //     <div>There is a mismatch between the login data and the parameters in the url</div>
                    //     <div>You log in as <span className="fw-bold">{session.profile.email}</span> while the email is sent to <span className="fw-bold">{raffleWinner?.winner?.email}</span></div>
                    // </Col>
                }
            </Row>

            <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)} backdrop='static' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Login
                        setShowModal={setShowLoginModal}
                        setLogin={setLogin}
                        setCart={setCart}
                        setIsLoged={setIsLoged}
                    />

                </Modal.Body>
            </Modal>
        </Container>
    )
}