import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row, Alert, Image, Stack } from "react-bootstrap";
import { Link, useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { Button, Dimmer, Icon, Label, Loader } from "semantic-ui-react";
import ProductCard from "../../component/ProductCard";
import ProductFilter from "../../component/ProductFilter";
import nodata from "../../assets/no_data.png"
import { useMyGlobal } from "../../component/GlobalData";

const ProductList = () => {
    const category = useLocation().state
    const path = useLocation().pathname
    const param = useParams()
    const idCustomer = (localStorage.getItem('loginDataRr') !== null) ? JSON.parse(localStorage.getItem('loginDataRr')).id : null
    const [products, setProducts] = useState([])
    const [colorList, setColorList] = useState([])
    const [brandList, setBrandList] = useState([])
    const [directLinkCat, setDirectLinkCat] = useState(null)
    const [sizeList, setSizeList] = useState([])
    const { token } = useMyGlobal()
    const [load, setLoad] = useState(true)
    const [hideImageEmpty, setHideImageEmpty] = useState(true)
    const { wishData, isPortrait, windowWidth, categoryAll } = useMyGlobal()

    const EmptyCategory = () => {
        return (
            <Alert variant="secondary" className="text-start mt-5">
                <Alert.Heading>This category does not contain any products!</Alert.Heading>
                <div>
                    This category does not contain items, maybe no items have been added to this category,
                    please click <Link to="/">Back to home</Link> to return to the category menu
                </div>
            </Alert>
        )
    }



    // const getFlashsale = (idFs) => {
    //     const param = {
    //         id_fs : idFs
    //     }

    //     axios({
    //         method: "POST",
    //         url: "http://localhost/api_rudrunner/controler/event.php/?index=3",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //         },
    //         data: param
    //     })
    //     .then(res => {
    //         console.log(res.data)
    //     })
    // }

    const getProductList = async (id_category, id_customer, option) => {
        const param = new URLSearchParams()
        if (id_customer === null) { id_customer = false }

        param.append('id_category', id_category)
        param.append('id_customer', id_customer)
        param.append('option', option)
        if (category?.hasOwnProperty('search_value')) param.append('param', category.search_value)
        setLoad(true)
        await axios({
            method: "POST",
            url: "https://hoopsdevteam.com/api_web/controler/product_rr.php?index=1",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        })
            .then((response) => {
                let data = []
                let colors = []
                let brand = []
                let size = []

                if (response.data.state == 200) {
                    data = response.data.data
                    data.map((i, d) => i.color.map((x) => colors.push(x)))
                    data.map((i, d) => i.size.map((x) => size.push(x)))
                    if (category?.from !== 'parent') {
                        data.map((i, d) => i.category_parent.map((x) => brand.push(x)))
                        setBrandList(Object.values(brand.reduce((acc, item) => {
                            if (!acc[item.id_category] && item.on_page === '1') {
                                acc[item.id_category] = {
                                    id_category: item.id_category,
                                    category_name: item.category_name,
                                    on_page: item.on_page,
                                };
                            }
                            return acc
                        }, {})).sort((a, b) => a.category_name.localeCompare(b.category_name)))
                    } else {
                        if (category.hasOwnProperty('child')){
                            category.child.map((i, d) => brand.push(i))
                        }
                        setBrandList(Object.values(brand.reduce((acc, item) => {
                            if (!acc[item.id_category_child]) {
                                acc[item.id_category_child] = {
                                    id_category: item.id_category_child,
                                    category_name: item.category_name,
                                    on_page: '0',
                                };
                            }
                            return acc
                        }, {})).sort((a, b) => a.category_name.localeCompare(b.category_name)))
                    }

                    if (response.data.data.length > 0) {
                        setLoad(false)
                        setHideImageEmpty(true)
                    } else {
                        setLoad(false)
                        setHideImageEmpty(false)
                    }
                    setProducts(data)
                }
                // GROUPING ARRAY SUPAYA GA DUPLICAT
                setColorList(Object.values(colors.reduce((acc, item) => {
                    if (!acc[item.id_color]) acc[item.id_color] = {
                        id_color: item.id_color,
                        color_main: item.color_main,
                        color_secondary: item.color_secondary,
                        color_accent: item.color_accent
                    }

                    return acc
                }, {})))

                const sizeGroup = [...new Set(size)].map(String)

                const convertedSizeGroup = sizeGroup.map((value) => {
                    // Jika nilai adalah pecahan seperti '1½'
                    const fractionMatch = value.match(/(\d+)½/);
                    if (fractionMatch) {
                        const wholeNumber = fractionMatch[1] || '0';
                        return `${wholeNumber}.5`;
                    }

                    // Jika nilai bukan pecahan, kembalikan nilai asli
                    return value;
                });

                const convertedNumericGroup = sizeGroup.map((value) => {
                    // Jika nilai adalah angka atau pecahan seperti '1½'
                    const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;

                    return numericValue;
                });
                // Membuat dua array: satu berisi nilai numerik, satu berisi nilai yang tidak bisa diurutkan
                const sortableValues = convertedNumericGroup.filter(value => typeof value === 'number');
                const nonSortableValues = convertedNumericGroup.filter(value => typeof value !== 'number');

                // Mengurutkan nilai numerik
                const sortedNumericValues = sortableValues.sort((a, b) => a - b);

                // Menggabungkan kembali nilai yang telah diurutkan dengan nilai yang tidak bisa diurutkan
                const finalResult = [...sortedNumericValues, ...nonSortableValues];
                setSizeList(finalResult);
            })
    }

    useEffect(() => {
        if (category !== null) {
            getProductList(param.categoryId, idCustomer, category?.from)
            window.scrollTo(0, 0)
        }
    }, [category, param])

    useEffect(() => {
        let part = path.split('/').filter(Boolean)
        let idCategoryChild = part[part.length - 1]
        if (category === null) {    
            getProductList(idCategoryChild, null, 'child')
            setDirectLinkCat(categoryAll.find(category =>
                category.child.some(child => child.id_category_child == idCategoryChild)
            ))
        }
    }, [path, categoryAll, category])
   
    return (
        <section style={{ height: '100%', minHeight: '100vh' }}>
            <Row className="justify-content-center">
                <Col xs={12} md={12} xl={12} className="">
                    <img
                        className="img-fluid"
                        src={category ? category.header_image : directLinkCat ? directLinkCat.header_image : ''}
                        width={'100%'}
                    />
                </Col>
            </Row>

            <Container>
                <Row className="mt-4">
                    <Col xxl={12} xl={12} className="text-start">
                        {
                            (category?.isDefault == 0) ?
                                <ProductFilter
                                    setProducts={(category?.hasOwnProperty('search_value')) ? null : setProducts}
                                    categoryId={param.categoryId}
                                    categoryFrom={category?.from}
                                    colorList={colorList}
                                    brandList={brandList}
                                    sizeList={sizeList}
                                    doGetProduct={false}
                                /> : <></>
                        }
                    </Col>
                </Row>
                <Row className="mt-2" >
                    <Dimmer active={load} inverted>
                        <Loader active={load}>Loading..</Loader>
                    </Dimmer>
                    {
                        (products) && (
                            (products.length > 0) && (
                                products.map((item, i) => {
                                    const isWish = wishData.some(wishItem => wishItem.id_product === item.id_product);
                                    return (
                                        <ProductCard
                                            data={item} key={i} isWish={isWish}
                                        />
                                    )
                                })
                            )
                        )
                    }
                </Row>
            </Container>
        </section>
    )
}

export default ProductList;