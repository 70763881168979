import React, { useEffect } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { Icon, Label, List } from "semantic-ui-react";

const ContactPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <section style={{ marginTop: "10rem", minHeight: "100vh", height: "100%" }}>
                <Container>
                    <Row className="d-flex justify-content-center mb-4">
                        <Col md={12}>
                            <div className="h2">Contact Information</div>
                            <div className="fs-5 text-muted" style={{ fontWeight: 'lighter' }}>
                                This is an information page about our location
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center mb-3">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="contact-container">
                                <div className="contact-info">
                                    <Stack direction="horizontal" gap={2} className="fs-3 mt-5 ms-5 pe-5">
                                        <div style={{ minWidth: '40px' }}><Icon circular name="map marker alternate" /></div>
                                        <Stack direction="vertical" gap={1}>
                                            <div className="ms-3 mb-2 fs-4">Address</div>
                                            <div className="ms-3 fs-5">Jl. Surapati No.61 Sadang Serang, Kecamatan Coblong.</div>
                                            <div className="ms-3 fs-5">Kota Bandung, Jawa Barat 40123</div>
                                        </Stack>
                                    </Stack>
                                    {/* <Stack direction="horizontal" gap={2} className="fs-3 mt-5 ms-5 pe-5">
                                        <div style={{minWidth:'40px'}}><Icon circular name="phone" /></div>
                                        <Stack direction="vertical" gap={1}>
                                            <div className="ms-3 mb-2 fs-4">Phone</div>
                                            <div className="ms-3 fs-5">022 - 2506999</div>
                                        </Stack>
                                    </Stack> */}
                                    <Stack direction="horizontal" gap={2} className="fs-3 mt-5 ms-5 pe-5">
                                        <div style={{ minWidth: '40px' }}><Icon circular name="whatsapp" /></div>
                                        <Stack direction="vertical" gap={1}>
                                            <div className="ms-3 mb-2 fs-4">Whatsapp</div>
                                            <div className="ms-3 fs-5"><a href="https://wa.me/085220899946">0852-2089-9946</a></div>
                                        </Stack>
                                    </Stack>
                                </div>
                                <div className="contact-map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.9281060550725!2d107.61846207499623!3d-6.8992019931000454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e7b14ffffffb%3A0x680778656c089b74!2sAdidas!5e0!3m2!1sid!2sid!4v1717579772630!5m2!1sid!2sid"
                                        width="100%" height="100%"
                                        className="contact-map-map"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col className="mt-5" xl={12} lg={12} md={12} sm={12}>
                            <Row className="d-flex justify-content-start">
                                {/* <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d106720.59787426075!2d106.76112971956006!3d-6.260049012967753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f1aa01c2db73%3A0xbe5af127eac26728!2sHOOPS%20Indonesia!5e0!3m2!1sid!2sid!4v1710311650521!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Senayan City JKT)</div>
                                            <div>Phone : 0812-1981-8713</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.5185934581314!2d106.81802247475034!3d-6.19509629379255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5d9953daefb%3A0x447ec9223c2308f5!2sHoops!5e0!3m2!1sid!2sid!4v1710312074606!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Grand Indonesia JKT)</div>
                                            <div>Phone : 0813-8386-2135</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.802753974977!2d106.90323857731973!3d-6.157166477921012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5f3d51f891f%3A0x80fa80e67de481a3!2sHoops%20Indonesia!5e0!3m2!1sid!2sid!4v1710312214782!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (MKG JKT)</div>
                                            <div>Phone : 0812-9261-0462</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.677674673257!2d106.78702247475007!3d-6.173890693813482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f71a9d4de82b%3A0x98899137b4da0f76!2sHoops%20Central%20Park!5e0!3m2!1sid!2sid!4v1710382339949!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Central Park JKT)</div>
                                            <div>Phone : 0812-8516-2484</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63466.17571046646!2d106.77736051774461!3d-6.179455368687346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f6425748ef2b%3A0x9431adffaa318ea7!2sHoops%20Indonesia%20Grogol!5e0!3m2!1sid!2sid!4v1711431291586!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Grogol JKT)</div>
                                            <div>Phone : 0812-9713-5252</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126920.9148890829!2d106.46698236465458!3d-6.226952701831223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fc1f9ad68e75%3A0x50b1748c82e5b672!2sHoops%20Indonesia%20Karawaci!5e0!3m2!1sid!2sid!4v1710383304270!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Karawaci TRG)</div>
                                            <div>Phone : 0857-8174-9708</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1753642860135!2d106.62490119440935!3d-6.240604410911625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fd2fc76f684d%3A0xe36aa113aacc648a!2sHoops%20Indonesia%20Sumarecon%20Mall%20Serpong!5e0!3m2!1sid!2sid!4v1711431609551!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Summarecon Serpong TRG)</div>
                                            <div>Phone : 0811-9731-661</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d849095.0197691083!2d114.97948108353!3d-8.659625631039823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd2470d257a7ef5%3A0xd252add1304b3a3c!2sHoops%20Indonesia%20Mall%20Bali%20Galeria!5e0!3m2!1sid!2sid!4v1711431774706!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Mall Galeria Bali)</div>
                                            <div>Phone : 0812-6275-9388</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2023188.195437087!2d114.48399250021984!3d-7.943071926507864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd23fb111e0945b%3A0x9e3f25a30c48fbad!2sHoops%20Indonesia%20Living%20World%20Bali!5e0!3m2!1sid!2sid!4v1710383666698!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (living World Bali)</div>
                                            <div>Phone : 0813-9261-2588</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253284.9525721379!2d112.50903058905446!3d-7.288829340887798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fd773bc490cf%3A0x3f78bed616093911!2sHoops%20Indonesia%20Pakuwon%20Mall!5e0!3m2!1sid!2sid!4v1711432118061!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Pakuwon Mall SBY)</div>
                                            <div>Phone : 0822-5746-3995</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253284.9525721379!2d112.50903058905446!3d-7.288829340887798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fa3b5b94c4a1%3A0x8910ee4776002c89!2sHoops%20Indonesia%20Galaxy%20Mall!5e0!3m2!1sid!2sid!4v1711432170871!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Galaxy Mall SBY)</div>
                                            <div>Phone : 0811-3443-843</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253284.9525721379!2d112.50903058905446!3d-7.288829340887798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7f9b0c3e0ca57%3A0xfa2c2b9437885e69!2sHoops%20-%20Tunjungan%20Plaza%201!5e0!3m2!1sid!2sid!4v1711432231223!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Tunjungan Plaza SBY)</div>
                                            <div>Phone : 0813-3080-4824</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d506981.9448405797!2d107.036190032959!3d-6.914924128924453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e73072074f99%3A0x70e735a6352fcaf3!2sHoops%20Indonesia%20Paskal%2023!5e0!3m2!1sid!2sid!4v1710383522234!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Paskal 23 BDG)</div>
                                            <div>Phone : 0858-7106-7757</div>
                                        </div>
                                    </div>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default ContactPage