import { Col, Image, Row } from "react-bootstrap"
import { Container, Icon } from "semantic-ui-react";
import visaLogo from "../assets/visa.png";
import masterCardLogo from "../assets/master_card.png";
import jcbLogo from "../assets/jcb.png";
import bcaLogo from "../assets/bca2.png";
import { AiOutlineYoutube, AiOutlineInstagram, AiOutlineFacebook } from "react-icons/ai"
import { useNavigate } from "react-router-dom";
import { useMyGlobal } from "./GlobalData";
import brandLogoFooter from "../assets/brand-footer.png";
import visa from "../assets/payment_icon/Payment icon set-01.png"
import mastercard from "../assets/payment_icon/Payment icon set-02.png"
import jcb from "../assets/payment_icon/Payment icon set-03.png"
import gopay from "../assets/payment_icon/Payment icon set-04.png"
import alfamart from "../assets/payment_icon/Payment icon set-05.png"
import alfamidi from "../assets/payment_icon/Payment icon set-06.png"
import spay from "../assets/payment_icon/Payment icon set-07.png"
import dandan from "../assets/payment_icon/Payment icon set-08.png"
import dana from "../assets/payment_icon/Payment icon set-09.png"
import ovo from "../assets/payment_icon/Payment icon set-10.png"
import bca from "../assets/payment_icon/Payment icon set-11.png"
import linkaja from "../assets/payment_icon/Payment icon set-12.png"
import mandiri from "../assets/payment_icon/Payment icon set-13.png"
import permata from "../assets/payment_icon/Payment icon set-14.png"
import bni from "../assets/payment_icon/Payment icon set-15.png"
import bri from "../assets/payment_icon/Payment icon set-16.png"
import { accentColor } from "./utility";

const FooterMain = () => {
    const navigate = useNavigate()
    const { isPortrait, windowWidth } = useMyGlobal()

    return (
        <section>
            <Container fluid style={{ borderTop: 'dashed 1px #D7D7D7', marginTop: '3%' }}>
                <Row className="d-flex justify-content-center mt-2">
                    <Col xs={12} sm={10} md={6} lg={6} xl={6}>
                        <Image src={brandLogoFooter} fluid width="80%"/>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <ul className="list-inline text-black-50">
                            <li className="list-inline-item c-color pointer" onClick={() => navigate('/term/termofuse')}>Term of Use |</li>
                            <li className="list-inline-item c-color pointer" onClick={() => navigate('/term/returnPolicy')}>Return Policy |</li>
                            <li className="list-inline-item c-color pointer" onClick={() => navigate('/home/sizeGuide')}>Size Guide |</li>
                            <li className="list-inline-item c-color pointer" onClick={() => navigate('/contact/contactPage')} style={{ cursor: 'pointer' }}>Contact </li>
                        </ul>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <ul className="list-inline">
                            <li className="list-inline-item me-3 border rounded p-1">
                                <a href="">
                                    <AiOutlineInstagram size={24} style={{ color: 'grey' }} className="zoom" />
                                </a>
                            </li>
                            <li className="list-inline-item me-3 border rounded p-1">
                                <a href="">
                                    <AiOutlineYoutube size={24} style={{ color: 'grey' }} className="zoom" />
                                </a>
                            </li>
                            <li className="list-inline-item border rounded p-1">
                                <a href="">
                                    <AiOutlineFacebook size={24} style={{ color: 'grey' }} className="zoom" />
                                </a>
                            </li>
                        </ul>

                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xl={12} lg={12} md={10} sm={12} xs={12}>
                        <ul className="list-inline">
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={visa}
                                    width={30}
                                    height={12}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={mastercard}
                                    width={30}
                                    height={19}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={jcb}
                                    width={30}
                                    height={18}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={bca}
                                    width={35}
                                    height={14}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={mandiri}
                                    width={38}
                                    height={13}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={bni}
                                    width={35}
                                    height={10}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={bri}
                                    width={32}
                                    height={10}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={permata}
                                    width={45}
                                    height={13}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={gopay}
                                    width={38}
                                    height={9}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={ovo}
                                    width={32}
                                    height={10}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={spay}
                                    width={34}
                                    height={13}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={dana}
                                    width={35}
                                    height={10}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={linkaja}
                                    width={27}
                                    height={18}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={alfamart}
                                    width={38}
                                    height={13}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={alfamidi}
                                    width={47}
                                    height={11}
                                />
                            </li>
                            <li className="list-inline-item border rounded p-1 mb-2">
                                <img
                                    src={dandan}
                                    width={38}
                                    height={13}
                                />
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row className="mb-3 mt-3">
                    <Col className={windowWidth < 600 ? "mb-5" : "mb-3"}>
                        <label className="text-black-80">&copy;2024. </label>
                        <label className="" style={{ color: accentColor }}>&nbsp;JFS Developer.</label>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default FooterMain;