import React, { useEffect, useRef, useState } from "react";

const TimerCountDown = ({ expiryTime, setCurentTime, cancelOnExpire, sendTime }) => {
    const Ref = useRef(null)
    const [timer, setTimer] = useState('00:00:00')
    const idCustomer = (localStorage.getItem('loginDataRr')) ? JSON.parse(localStorage.getItem('loginDataRr')).id : null

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60));
        
        if (sendTime){
            setCurentTime(total)
        }
      
        // if (total <= 0){
        //     cancelOnExpire(idCustomer)
        // }
    
        if (total <= 0) clearInterval(Ref.current)
        return {
            total, hours, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, hours, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ' : ' +
                (minutes > 9 ? minutes : '0' + minutes) + ' : '
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }

    const clearTimer = (e) => {
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }


    useEffect(() => {
        clearTimer(expiryTime);
    }, []);

    return (timer)
}

export default TimerCountDown